.form-book form input{
    display: list-item;
    width: 300px;
    padding: 10px;
    outline: none;
    background-color: inherit ;
    border: none;
    border-bottom-style:ridge;
    border-bottom-color:rgb(47, 184, 184) ;
    font-size: 1.2rem;
    color:black;
    margin: 0 auto;
}
.form-book form{
    text-align: center;
    align-items: center;
}
.form-book{
    margin: 0 auto;
    color: black;
    font-size: 1.5rem;
    padding: 30px;
    width: 500px;
    border-radius: 20px;
    background-color: blueviolet;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    background-color: rgba(71, 193, 174, 0.2);
}
@media only screen and (max-width: 768px) {
    .form-book{
        font-size: 1.2rem;
        padding: 5px;
        width:250px;
    }
    .form-book form input{
        width: 200px;
        padding: 5px;
        font-size: 1.2rem;
    }
}
