.single-container{
    padding: 30px;
}
.singlecar-boxing{
    display:flex;
    justify-content: space-evenly;
    align-items: stretch;
    color: black;
    background-size: cover;
    background-repeat: no-repeat;
}
.cardiv-right{
   padding: 20px;
}
.cardiv-right img{
    width: 100%;
    max-width: 750px;
    min-width: 700px;
 }
.cardiv-left{
    border-radius: 20px;
    padding: 30px;
    width: 300px;
    height: fit-content;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    background-color: rgba(71, 193, 174, 0.2);
}
.cardiv-left table td{
    margin: 0;
    list-style: none;
    padding: 5px;
    font-size: 1.5rem;
    overflow: hidden;
}
.cardiv-mid{
    padding: 20px;
    width: 200px;
    font-size: 1.5rem;
    text-align: center;
    margin: 0 auto;
}
.cardiv-mid .btn-1{
    padding: 15px;
    border-radius: 30%;
}
.search-bar{
    margin: 0;
    padding: 3rem;
    display: flex;
    font-size:1.5rem ;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
}
.vl {
    border-left: 5px solid #0a7ef5;
    margin-left: 20px;
}
.search-bar .button-div{
    margin: 0 auto;
}
.search-bar .button-div .seachbtn{
    padding: 10px;
    margin: 20px;
    background-color: rgb(47, 184, 184);
    color: #fff;
    border-radius: 30px;
    border: none;
    font-size: 1.1rem;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 767px){
    .single-container{
        padding: 0;
    }
    .cardiv-right{
        padding: 20px;
        margin: 0;
     }
     .cardiv-left{
        margin: 0 auto;
        padding: 0;
     }
     .cardiv-right img{
        width: 100%;
        max-width: 250px;
        min-width: 250px;
        margin: auto;
        display: block;
     }
     .singlecar-boxing{
        flex-direction: column;
     }
     .search-bar{
        margin: 0;
        padding: 2rem;
        display: flex;
        font-size:1.2rem ;
        box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
    }
    .vl {
        border-left: 5px solid #0a7ef5;
        margin-left: 20px;
    }
    .search-bar .button-div{
        margin: 0 auto;
    }
    .search-bar .button-div .seachbtn{
        padding: 5px;
        margin: 10px;
        background-color: rgb(47, 184, 184);
        color: #fff;
        border-radius: 30px;
        border: none;
        font-size: 0.8rem;
        outline: none;
    }
    .search-bar h2{
        font-size: 1.2rem;
    }
    .cardiv-mid{
        padding: 30px;
        padding-bottom: 0;
    }
}
@media only screen and (max-width: 1150px){
    .cardiv-right{
        padding: 20px;
        margin: 0;
     }
     .cardiv-left{
        margin: 0 auto;
        padding: 0;
     }

     
     .cardiv-right img{
        width: 100%;
        max-width: 300px;
        min-width: 300px;
        margin: auto;
        display: block;
     }
     .cardiv-left{
        margin: 0 auto;
     }
     .singlecar-boxing{
        justify-content: space-between;
     }
     .search-bar{
        margin: 0;
        padding: 2rem;
        display: flex;
        font-size:1.2rem ;
        box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
    }
    .vl {
        border-left: 5px solid #0a7ef5;
        margin-left: 20px;
    }
    .search-bar .button-div{
        margin: 0 auto;
    }
    .search-bar .button-div .seachbtn{
        padding: 5px;
        margin: 10px;
        background-color: rgb(47, 184, 184);
        color: #fff;
        border-radius: 30px;
        border: none;
        font-size: 1rem;
        outline: none;
    }
    .search-bar h2{
        font-size: 1.1rem;
    }
}
