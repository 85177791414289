.reg-body{
    margin-top: 200px;
    display: flex;
    justify-content: space-around;
    color: black;
    margin-bottom: 50px;
}

.reg-inner form{
    display: flex;
    flex-direction: column;
    
}
.reg-inner form label{
    padding: 10px;
    padding-left: 0%;
    font-size: 1rem;
}
.reg-inner form input{
    width: 200px;
    border:none ;
    outline: none;
    border-bottom: ridge;
    font-size: 1.3rem;
    background: inherit;
    color: black;
}
.reg-inner h1{
    font-size: 3rem;
}
.reg-inners{
    align-items: center;
}
.vline{
    border-left: 5px solid #0a7ef5;
    margin-left: 20px;
}


/* login*/
.regi-inner form{
    display: flex;
    flex-direction: column;
}
.regi-inner form label{
    padding:10px;
    font-size: 1rem;
}
.regi-inner form input{
    width: 200px;
    border:none ;
    outline: none;
    border-bottom: ridge;
    font-size: 1.3rem;
    background: inherit;
    color: black;
}
.regi-inner h1{
    font-size: 2rem;
}
.regi-inners{
   border-style: groove;
   border-color: wheat;
}
.reg-body .btn-1{
    margin-left: 55px;
}
.reg-container .foot{
    position: fixed;
    bottom: 0;
    width: 100%;
}
.link-for{
    margin-bottom: 70px;
}

@media only screen and (max-width :768px){
    .reg-body{
        flex-direction: column;
        text-align: center;
        align-items:center ;
        margin-top: 10px;
    }
    .reg-body .vline{
        border: 2px solid #0a7ef5;
        margin-top: 30px;
        width: 70%;
        margin-bottom: 30px;
    }
    .regi-inner{
        margin-top: 30px;
    }
}