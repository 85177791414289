.inner-body{
    color: white;
    padding-top: 20px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.inner-about h1{
    text-align: center;
    padding: 30px;
}
.about-body .foot{
    position: fixed;
    bottom: 0;
    width: 100%;
}
.about-para p{
    font-size: 2rem;
    word-spacing: 5px;
    line-height: 4rem ;
    justify-content: center;
    margin: 30px;
    margin-bottom: 80px;
}
@media only screen and (max-width: 768px){
    .about-para p{
        font-size: 1rem;
        word-spacing: 5px;
        line-height: 2rem ;
    }
}
    
