.login-body{
    margin-top: 200px;
    display: flex;
    justify-content: space-evenly;
    color: black;
    margin-bottom: 30px;
}
.login-inner form{
    display: flex;
    flex-direction: column;
}
.login-inner form label{
    padding: 30px;
    padding-left: 0%;
    font-size: 2rem;
}
.login-inner form input{
    width: 200px;
    border:none ;
    outline: none;
    border-bottom: ridge;
    font-size: 1.5rem;
    background: inherit;
    color: black;

}
.login-inner h1{
    font-size: 3rem;
}
.login-inners{
    justify-content: space-between;
}
.ques-img{
    text-align: center;
}
.ques-img img{
    width: 350px;
}
.login-container .foot{
    position:fixed;
    bottom: 0;
    width: 100%;
}
@media only screen and (max-width: 768px){
    .login-body{
        flex-direction: column;
        margin-top: 0;
    }
    .login-inner{
        margin: 0;
        padding: 0;
    }
    .login-inner form input{
        text-align: center;
        align-items: center;
    }
    .login-inner form{
        align-items: center;
    }
    .ques-img img{
        width: 300px;
    }
    .ques-img{
        padding: 20px;
    }
    .ques-img .btn-1{
        margin-bottom: 50px;
    }
    .login-container .vline{
        border: 2px solid #0a7ef5;
        width: 70%;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}