
.contact-form input{
    display: list-item;
    margin: auto;
    width:200px;
    padding: 10px;
    outline: none;
    background-color: inherit ;
    border: none;
    border-bottom-style:ridge;
    border-bottom-color:rgb(47, 184, 184) ;
    font-size: 1rem;
    color: black;
}
.contact-form{
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    padding: 30px;
    font-size: 1.5rem; 
    width: 300px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    background-color: rgba(71, 193, 174, 0.2);
}
.contact-details{
    text-align: center;
    padding: 10px;
    margin-bottom: 70px;
}
.contact-details img{
    margin: 50px;
    width: 300px;
}
.contact-body .foot{
    position: fixed;
    bottom: 0;
    width: 100%;
}


@media only screen and (max-width: 768px){
    .contact-form{
    padding: 30px;
    font-size: 1.5rem;
    width: 200px;
    height: auto;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
}
.contact-form input{
    width: 150px;
}
.contact-details img{
    width: 220px;
    padding: 0;
    margin: 0;
}
.contact-details h2{
    font-size: 1.2rem;
}
.contact-details{
    margin-bottom: 70px;
}
}