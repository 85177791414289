.home-body{
    margin: 0;
    padding: 0; 
    color: rgb(255, 255, 255);
    width: 100%;
}
.taxi-img{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
.taxi-img img{
    width: 800px;
}
.inner-home .head-h1{
    text-align: center;
    font-size: 2.2rem;
    padding: 10px;
}
.about-content{
    padding: 30px;
}
.homepage-content{
    margin-top: 300px;
    display: flex;
    height: 700px;
    justify-content: space-evenly;
    color: rgb(255, 255, 255)
}
.homepage-left{
    padding: 20px;
}
.homepage-right{
    padding: 20px;
    text-align: center;
    margin-top: 100px;
}
.homepage-left img{
    width: 500px;
    
}
.home-cardetail li{
    list-style: none;
    font-size: 1.5rem;
}
.btn{
    margin-top:10px ;
}
.home-1{
    display: flex;
    height: 700px;
    justify-content: space-around;
}
.home-services{
    margin: 0;
    margin-top: 200px;
    text-align: center;
}

.home-body .foot{
    position: fixed;
    bottom: 0;
    width: 100%;
}
.inner-home .side-car{
    width: 100%;
}
.footer-div{
    height: 280px;
    background-color:rgb(18,184,184);
    text-align: center;
}
.social{
    padding-top:10px ;
    display: flex;
    margin: 0 auto;
    
}
.footer-div .add{
    padding: 50px;
}
@media only screen and (max-width: 768px){ 
    .homepage-content{
        flex-direction: column;
        height: max-content;
        margin: 0;
    }
    .logo{
       max-width: 100%;
       height: 80%;
       padding: 0;
       margin: 0;
       display: block;
       margin-left: auto;
       margin-right: auto;
    }
    .homepage-left{
        margin: 0;
        height: fit-content;
        align-items: center;
    }
    .homepage-right{
        padding: 0px;
        height: fit-content;
        margin: 0;
    }
    .footer-div{
        height:250px;
        font-size: 0.8rem;
    }
    .footer-div .add{
        padding: 20px;
    }
    .taxi-img{
        margin: 0;
        align-items: center;
        height: fit-content;
    }
    .taxi-img img{
        width: 350px;
    }
    .door-stepimg {
        align-items: center;
        height: fit-content;
        margin: 0;
    }
    .door-stepimg img{
       max-width: 100%;
       height: auto;
       display: block;
       margin-left: auto;
       margin-right: auto;
    }
    .home-1{
        flex-direction: column;
        justify-content: center;
    }
    .home-services{
        margin: 0;
    }
}