.adhome-dasboard{
    display: flex;
}
.total-cars, .reserved-cars, .non-available-cars, .available-cars{
    margin: 20px;
    position: relative;
    width: 500px;
    height: 200px;
    border-radius: 20px;
    box-shadow: 0 15px 25px rgba(33, 2, 2, 0.2);
    background-color: rgba(71, 193, 174, 0.2);
}
.total-cars, .reserved-cars, .non-available-cars, .available-cars h2{
    text-align: center;   
}
.adhome-dasboard h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.adhome-dasboard .btn-1{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%,-80%);
}


@media screen and (max-width: 768px) {
    .adhome-dasboard{
        flex-direction: column;
    }
    .total-cars, .reserved-cars, .non-available-cars, .available-cars{
        margin: 0 auto;
        position: relative;
        font-size: 10px;
        width: 200px;
        height: 150px;
        border-radius: 20px;
        box-shadow: 0 15px 25px rgba(33, 2, 2, 0.2);
        background-color: rgba(71, 193, 174, 0.2);
    }
}