*{
  /* #2fb8b8 */
  margin: 0;
  padding: 0;
  color:Black;
}

body {
  margin: 0;
  background-color: #ebf7f7;
  color:Black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none;
  color: inherit;
}
.btn, .btn-1{
  border: none;
  outline: none;
  background-color: #0a7ef5;
  color: white;
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  font-size: 1rem;
  cursor: pointer;
}
.btn:hover{
  background-color: #0a7ef5;
  color: white;
  border: none
}
.vline{
  border-left: 5px solid #0a7ef5;
  margin-left: 20px;
}
h1{
  text-align: center;
  padding: 10px;
  color: black;
}
.btn-2{
  background-color: #0a7ef5;
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 20px;
  font-size: 1rem;
}
@media only screen and(max-width: 768px){
  *{
    padding: 0;
    margin: 0;
  }
  .container{
    padding: 0;
    margin: 0;
  }
}