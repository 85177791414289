.booking-body{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 10px;
}
.book-body{
    color: white;
}
.book-body h1{
    padding: 30px;
    text-align: center;
}
.bookinfo{
    margin: 0 auto;
    padding: 30px;
}
.booking-left td{
    color:black;
    padding: 15px;
    font-size: 2rem;
}
.booking-left{
    width: fit-content;
    height: fit-content;
    border-radius: 20px;
    box-shadow: 0 15px 25px rgba(33, 2, 2, 0.2);
    background-color: rgba(71, 193, 174, 0.2);
}
@media only screen and (max-width: 768px){
    .booking-body{
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        padding-bottom: 10px;
    }
    .booking-right {
        padding: 10px;
        margin: 0 auto;
    }
    .booking-right img{
        width: 280px;
    }
    .booking-left td{
        color:black;
        padding: 5px;
        font-size: 1.5rem;
    }
    .booking-left{
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
}
